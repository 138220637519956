<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();

const onBackToMain = () => {
  router.replace("/index");
};
</script>

<template>
  <div class="onHome" @click="onBackToMain" v-click:oeReport.action="'onBackToMain'">
    <!-- <van-icon name="wap-home-o" /> -->
    <img src="../../assets/images/icon_backmain.png" alt="" />
  </div>
</template>

<style lang="less" scoped>
.onHome {
  width: 100px;
  height: 100px;
  position: fixed;
  right: 40px;
  bottom: 300px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
