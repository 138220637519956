import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/common";
const params: AnyObject = getUrlParams(window.location.href);
const getRedirect = () => {
  if (process.env.VUE_APP_START == "index") return "/index";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else if (params.qapp) return "/guide";
  else return "/index";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/index",
    name: "Index",
    redirect: "/pageMain",
    component: () => import("@/views/HomeView.vue"),
    // meta: {
    //   spaceKeys: ["Home_inter", "Home_banner"]
    // },
    children: [
      {
        path: "/pageMain",
        name: "Home",
        component: () => import("@/views/home/PageMain.vue"),
        meta: {
          spaceKeys: ["Home_inter", "Home_top", "Home_banner", "Home_dialog"]
        }
      },
      {
        path: "/pageStory",
        name: "PageStory",
        component: () => import("@/views/home/PageStory.vue"),
        meta: {
          spaceKeys: ["Home_inter", "Home_top", "Home_banner"]
        }
      },
      {
        path: "/pageIdiom",
        name: "PageIdiom",
        component: () => import("@/views/home/PageIdiom.vue"),
        meta: {
          spaceKeys: ["Home_inter", "Home_top", "Home_banner"]
        }
      },
      {
        path: "/pageGuess",
        name: "PageGuess",
        component: () => import("@/views/home/PageGuess.vue"),
        meta: {
          spaceKeys: ["Home_inter", "Home_banner"]
        }
      }
    ]
  },
  {
    path: "/guide", // 启动页
    name: "Guide",
    component: () => import("@/views/guideView.vue"),
    meta: {
      spaceKeys: ["Guide_native", "Guide_banner"]
    }
  },
  {
    path: "/storyDetails", //故事详情
    name: "StoryDetails",
    component: () => import("@/views/storyCollection/details.vue"),
    meta: {
      spaceKeys: ["Story_native", "Story_banner", "Story_inter"]
    }
  },
  {
    path: "/secondary", //成语二级页面
    name: "Secondary",
    component: () => import("@/views/idiomCollection/secondary.vue"),
    meta: {
      spaceKeys: ["Idiom_list_native", "Idiom_list_banner"]
    }
  },
  {
    path: "/idiomDetails", //成语详情
    name: "IdiomDetails",
    component: () => import("@/views/idiomCollection/idiomDetails.vue"),
    meta: {
      spaceKeys: ["Idiom_list_native", "Idiom_list_banner", "Idiom_inter"]
    }
  },
  {
    path: "/interestIdiom", //趣味猜成语
    name: "InterestIdiom",
    component: () => import("@/views/guessCollection/interest.vue"),
    meta: {
      spaceKeys: ["Interest_guesss_native", "Interest_guesss_error"]
    }
  },
  {
    path: "/lookGuess", //看图猜成语
    name: "LookGuess",
    component: () => import("@/views/guessCollection/lookGuess.vue"),
    meta: {
      spaceKeys: ["Guess_banner", "Guess_next_native"]
    }
  },
  {
    path: "/riddleGuess", //谜语成语
    name: "RiddleGuess",
    component: () => import("@/views/guessCollection/riddleGuess.vue"),
    meta: {
      spaceKeys: ["Guess_banner", "Guess_next_native"]
    }
  },
  {
    path: "/sayingGuess", //成语歇后语
    name: "SayingGuess",
    component: () => import("@/views/guessCollection/sayingGuess.vue"),
    meta: {
      spaceKeys: ["Guess_banner", "Guess_next_native"]
    }
  }
  // {
  //   path: "/idiomCollection", // 成语大全
  //   name: "IdiomCollection",
  //   component: () => import("@/views/idiomCollection/index.vue")
  // },
  // {
  //   path: "/storyCollection", //故事集
  //   name: "StoryCollection",
  //   component: () => import("@/views/storyCollection/index.vue")
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});
// router.beforeEach((to, from, next) => {
// console.error(to.path, from, "路由打印", process.env.VUE_APP_STAR);
// next();
// });
export default router;
